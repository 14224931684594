.App {
  text-align: center;
  --main-font:hotpink;

}

.About-us-heading {
  color: var(--main-font);
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: 'green';
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-heading{
  font:bold;
  color:var(--main-font);

}
.App-heading2{
  color:black;
  text-decoration: none;
  transition-duration: 0.4s;
}
.App-heading2:hover{
  text-decoration: underline;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
